import React from "react";
import { MARKETING_URL, DASHBOARD_URL } from "@bay1/ui/urlHelper";

// pages/404.js
// eslint-disable-next-line react/function-component-definition
export default function Custom404(): JSX.Element {
  return (
    <div className="grid h-screen w-screen bg-black bg-marquee bg-no-repeat bg-cover bg-center">
      <div className="mx-auto self-center">
        <h1 className="text-center text-3xl text-white">Page does not exist</h1>
        <p className="text-gray-200 text-center text-lg mb-3">
          That page does not exist or you do not have access to it.
        </p>
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <nav className="relative flex items-center justify-between sm:h-10 md:justify-center">
            <div className="flex space-x-10">
              <a
                className="text-white hover:text-gray-300 underline"
                href={String(MARKETING_URL)}
              >
                Homepage
              </a>
              <a
                className="text-white hover:text-gray-300 underline"
                href={`${MARKETING_URL}/docs`}
              >
                API &amp; Documentation
              </a>
              <a
                className="text-white hover:text-gray-300 underline"
                href={String(DASHBOARD_URL)}
              >
                Dashboard
              </a>
              <a
                className="text-white hover:text-gray-300 underline"
                href={`${MARKETING_URL}/contact`}
              >
                Support
              </a>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
